import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import HistoryId from "../../HistoryId"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import CircleBg from "../../../../static/svg/Large-Circle.svg"
import { GradientFooterBg } from "../../Footer"
import {
  Heading24,
  Heading32,
  Heading40,
  Paragraph18,
} from "../../../styles/PageStyles"

export const Carousel = ({ children }) => {
  function Count() {
    let count = 0
    children.map((child) => !child && count++)

    const childrenCount = children.length - count

    return childrenCount > 1
  }

  const carouselSettings = {
    dots: true,
    infinite: Count(),
    focusOnSelect: true,
    slidesToShow: Count() ? 2 : 1,
    slidesToScroll: Count() ? 2 : 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 750,
    pauseOnHover: false,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
          infinite: true,
          focusOnSelect: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return <StyledSlider {...carouselSettings}>{children}</StyledSlider>
}

const AboutUsContentId = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { templateKey: { eq: "about-us-id" } }) {
        frontmatter {
          greetings {
            hidden
            header
            presidentDirector {
              hidden
              title
              name
              jobTitle
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              body {
                paragraph
              }
            }
            ceo {
              hidden
              title
              name
              jobTitle
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              body {
                paragraph
              }
            }
          }
          ourHistory {
            description
            title
          }
          qhse {
            description {
              paragraph
            }
            terms {
              description
              orderedList {
                item
              }
              note
            }
            backgroundImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          certificates {
            title
            hidden
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  `)

  const { frontmatter } = data.markdownRemark
  const { presidentDirector } = frontmatter.greetings
  const { ceo } = frontmatter.greetings

  return (
    <AbousUsContainer fluid>
      {!frontmatter.greetings?.hidden && (
        <>
          <Row id="greetings" className="with-margin">
            <Heading40>{frontmatter.greetings?.header}</Heading40>
            {!presidentDirector?.hidden && (
              <GreetingsIndonesia>
                <div className="greeting-image">
                  <GatsbyImage
                    image={getImage(presidentDirector?.image)}
                    alt={`${presidentDirector?.name}, ${presidentDirector?.jobTitle}`}
                  />
                </div>
                <div className="greeting-content">
                  <Heading24>{presidentDirector?.title}</Heading24>
                  <Paragraph18>
                    {presidentDirector?.body?.map((message, index) => (
                      <ReactMarkdown key={index}>
                        {message?.paragraph}
                      </ReactMarkdown>
                    ))}
                  </Paragraph18>
                </div>
                <div className="greeting-title">
                  <Heading24>{presidentDirector?.name}</Heading24>
                  <Paragraph18>{presidentDirector?.jobTitle}</Paragraph18>
                </div>
              </GreetingsIndonesia>
            )}
          </Row>
          <Row className="with-margin pt-100 pb-100">
            {!ceo?.hidden && (
              <GreetingsHQ>
                <div className="greeting-image">
                  <GatsbyImage
                    image={getImage(ceo?.image)}
                    alt={`${ceo?.name}, ${ceo?.jobTitle}`}
                  />
                </div>
                <div className="greeting-content">
                  <Heading24>{ceo?.title}</Heading24>
                  <Paragraph18>
                    {ceo?.body?.map((message, index) => (
                      <ReactMarkdown key={index}>
                        {message?.paragraph}
                      </ReactMarkdown>
                    ))}
                  </Paragraph18>
                </div>
                <div className="greeting-title">
                  <Heading24>{ceo?.name}</Heading24>
                  <Paragraph18>{ceo?.jobTitle}</Paragraph18>
                </div>
              </GreetingsHQ>
            )}
          </Row>
        </>
      )}
      <Row id="history" className="with-margin">
        <OurHistory>
          <Heading32>Sejarah Kami</Heading32>
          <Paragraph18>
            <ReactMarkdown>{frontmatter.ourHistory?.description}</ReactMarkdown>
          </Paragraph18>
          <Heading24>
            <ReactMarkdown>{frontmatter.ourHistory?.title}</ReactMarkdown>
          </Heading24>
          <div className="history-image">
            <HistoryId />
          </div>
        </OurHistory>
      </Row>
      <QhseRow id="qhse">
        <Qhse>
          <Heading32>
            Kebijakan Mutu, Kesehatan, Keselamatan Kerja dan Lingkungan
          </Heading32>
          <Paragraph18>
            {frontmatter.qhse?.description?.map((description, index) => (
              <ReactMarkdown key={index}>
                {description?.paragraph}
              </ReactMarkdown>
            ))}
          </Paragraph18>
        </Qhse>
        <div className="terms-wrapper">
          <Col className="terms">
            <Paragraph18>
              <ReactMarkdown>
                {frontmatter.qhse?.terms?.description}
              </ReactMarkdown>
            </Paragraph18>
            <ol style={{ listStyleType: "lower-alpha" }}>
              {frontmatter.qhse?.terms?.orderedList?.map((list, index) => (
                <li key={index}>
                  <Paragraph18 style={{ margin: 0 }}>
                    <ReactMarkdown>{list.item}</ReactMarkdown>
                  </Paragraph18>
                </li>
              ))}
            </ol>
            <div>
              <Paragraph18>
                <ReactMarkdown>{frontmatter.qhse?.terms?.note}</ReactMarkdown>
              </Paragraph18>
            </div>
          </Col>

          <Col className="qhse-bg-wrapper">
            <GatsbyImage
              className="qhse-bg-img"
              image={getImage(frontmatter.qhse?.backgroundImage)}
              alt="Quality, Health, Safety and Environmental Policy"
            />
          </Col>
          <CircleBg className="circle-bg" />
        </div>
      </QhseRow>

      <div className="horizontal-border" />
      <Row className="certificates">
        <Heading32>Sertifikasi Kami</Heading32>
        <Carousel>
          {frontmatter.certificates?.map(
            (certificate, index) =>
              !certificate.hidden && (
                <div key={index}>
                  <CarouselItem>
                    <GatsbyImage
                      image={getImage(certificate?.image)}
                      alt={certificate?.title}
                      draggable={false}
                    />
                    <Heading24>{certificate?.title}</Heading24>
                  </CarouselItem>
                </div>
              )
          )}
        </Carousel>
        <CircleBg className="circle-bg" />
      </Row>
      <GradientFooterBg />
    </AbousUsContainer>
  )
}

export default AboutUsContentId

const AbousUsContainer = styled(Container)`
  padding: 0;
  background: #f7f8fc;
  position: relative;
  z-index: 1;

  h1,
  p {
    margin-bottom: 25px;
  }

  h1 {
    text-align: center;
  }

  li {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0.06em;
    color: #5b5d5e;

    @media only screen and (max-width: 769px) {
      font-size: 16px;
    }
  }

  .horizontal-border {
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, #aac532 -11.34%, #d1e869 84.35%);
  }

  .certificates {
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 60px 10% 75px 10%;

    h1 {
      text-align: center;
    }

    .circle-bg {
      position: absolute;
      bottom: -600px;
      left: -900px;
      z-index: -1;
    }
  }

  @media only screen and (max-width: 1024px) {
    .pt-100 {
      padding-top: 50px;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    p,
    li {
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 400;
      letter-spacing: 0.06em;
    }
  }
`

const QhseRow = styled(Row)`
  position: relative;
  z-index: 1;
  overflow: hidden;

  .terms-wrapper {
    display: flex;
    margin-left: 10%;

    .terms {
      width: 40%;
      flex: unset;

      p,
      li {
        text-align: justify;
      }
    }

    .qhse-bg-wrapper {
      .gatsby-image-wrapper {
        z-index: -1;

        @media only screen and (min-width: 1441px) {
          width: 650px;
          position: absolute !important;
          bottom: 0;
          right: 0;
        }
      }

      @media only screen and (min-width: 1366px) and (max-width: 1440px) {
        height: 0;
        .gatsby-image-wrapper {
          width: 600px;
          position: absolute !important;
          bottom: 0;
          right: 0;
        }
      }
    }

    @media only screen and (max-width: 1365px) {
      margin: 0;
      padding: 0 10%;
      display: flex;
      flex-direction: column;

      .terms {
        width: 100%;
      }
    }
  }

  .circle-bg {
    position: absolute;
    right: -570px;
    bottom: -570px;
    z-index: -2;
  }
`

const GreetingsIndonesia = styled(Col)`
  position: relative;
  border: 2px solid #149be1;
  border-radius: 20px;
  padding: 0;
  margin-left: 150px;

  .greeting-image {
    position: absolute;
    top: 15%;
    left: -150px;
    border-radius: 50%;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    .gatsby-image-wrapper {
      width: 300px;
    }

    @media only screen and (max-width: 1366px) {
      left: -125.5px;

      .gatsby-image-wrapper {
        width: 250px;
      }
    }

    @media only screen and (max-width: 1024px) {
      position: absolute;
      top: 0;
      margin: 25px 0 25px 50%;
    }

    @media only screen and (max-width: 425px) {
      left: -125px;
    }
  }

  .greeting-content {
    margin-left: 175px;
    padding: 50px 50px 50px 0;

    h1 {
      font-weight: bold;
      text-align: left;
      white-space: nowrap;
    }

    p {
      text-align: justify;
      margin-bottom: 25px !important;
      &:last-child {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1388px) and (min-width: 1025px) {
      padding: 50px 50px 50px 175px;
      margin: 0;

      h1 {
        margin-left: -125px;
        white-space: normal;
        text-align: center;
      }
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 0 10% 10% 10%;
      margin-top: 300px;

      h1 {
        white-space: normal;
        text-align: center;
      }
    }
  }

  .greeting-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 175px;
    background: linear-gradient(180deg, #149be1 0%, #0073af 100%);
    border-radius: 0 0 20px 20px;
    border: 3px solid #fff;

    h1 {
      color: #ffffff;
      text-align: left;
      margin: 0;
    }

    p {
      font-weight: 500;
      text-align: left;
      color: #ffffff;
      margin: 0;
    }

    @media only screen and (max-width: 1024px) {
      padding: 10px 0;

      h1,
      p {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    margin: 0;
  }
`

const GreetingsHQ = styled(Col)`
  position: relative;
  border: 2px solid #d1e869;
  border-radius: 20px;
  padding: 0;
  margin-right: 150px;

  .greeting-image {
    position: absolute;
    top: 15%;
    right: -150px;
    border-radius: 50%;
    box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    .gatsby-image-wrapper {
      width: 300px;
    }

    @media only screen and (max-width: 1366px) {
      right: -125.5px;

      .gatsby-image-wrapper {
        width: 250px;
      }
    }

    @media only screen and (max-width: 1024px) {
      top: 0;
      margin: 25px 50% 25px 0;
    }

    @media only screen and (max-width: 425px) {
      right: -125px;
    }
  }

  .greeting-content {
    margin-right: 175px;
    padding: 50px 0 50px 50px;

    h1 {
      font-weight: bold;
      text-align: right;
      color: #aac532;
      white-space: nowrap;
    }

    p {
      text-align: justify;
      margin-bottom: 25px !important;
      &:last-child {
        margin: 0;
      }
    }

    @media only screen and (max-width: 1388px) and (min-width: 1025px) {
      padding: 50px 175px 50px 50px;
      margin: 0;
      margin-top: 0;

      h1 {
        margin-right: -125px;
        white-space: normal;
        text-align: center;
      }
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 0 10% 10% 10%;
      margin-top: 300px;

      h1 {
        white-space: normal;
        text-align: center;
      }
    }
  }

  .greeting-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 175px;
    background: linear-gradient(180deg, #d1e869 0%, #aac532 100%);
    border-radius: 0 0 20px 20px;
    border: 3px solid #fff;

    h1 {
      text-align: right;
      color: #ffffff;
      margin: 0;
    }

    p {
      font-weight: 500;
      color: #ffffff;
      text-align: right;
      margin: 0;
    }

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 10px 0;

      h1,
      p {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    margin: 0;
  }
`

const OurHistory = styled(Col)`
  h1 {
    font-weight: bold;
    text-align: left;

    * {
      color: #0073af;
      font-weight: bold;
    }

    @media only screen and (max-width: 1024px) {
      text-align: center !important;
    }
  }

  p {
    text-align: justify;
  }

  .history-image {
    display: flex;
    justify-content: center;
    margin: 25px 0 50px 0;

    svg {
      height: inherit;
    }

    @media only screen and (max-width: 1366px) {
      svg {
        width: 700px;
      }
    }
  }
`

const Qhse = styled(Col)`
  margin: 0 10%;
  padding: 0;

  h1 {
    text-align: left;

    @media only screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  p {
    text-align: justify;
    margin-bottom: 25px !important;
  }
`

const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 25px 0;

  .slick-list {
    width: 100%;
  }

  .slick-slide {
    margin-bottom: 35px;
  }

  .slick-slide img {
    object-fit: contain !important;
  }

  .slick-dots {
    li button:before {
      display: none;
    }

    .slick-active {
      button {
        background: #0073af;
      }
    }

    button {
      width: 18px;
      height: 18px;
      background: #bcc1c3;
      border-radius: 50%;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    .slick-list {
      width: 60%;
      margin: 0 auto;
    }
  }
`

const CarouselItem = styled.div`
  width: 500px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 5px 5px 30px -10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 25px;

  .gatsby-image-wrapper {
    height: 600px;
  }

  img {
    border-radius: 10px 10px 0 0;
  }

  h1 {
    margin: 0;
    font-weight: bold;
  }

  @media only screen and (min-width: 1024px) {
    width: 38vw;
    max-width: 500px;

    .gatsby-image-wrapper {
      height: 40vw;
      max-height: 600px;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 80vw;
    padding: 10px;

    .gatsby-image-wrapper {
      height: 90vw;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1440px) {
    width: 280px;
    padding: 15px;

    .gatsby-image-wrapper {
      height: 320px;
    }
  }
`
