import React from "react"
import { graphql } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import AboutUsContent from "../components/indonesian/about-us/AboutUsContentId"
import PageHeader from "../components/indonesian/PageHeaderId"

const AboutUsId = ({ data, location, i18nMessages }) => {
  const url = location.pathname
  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, url)
  const homeLink = `/${langKey}/`
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <Layout
        seoTitle="Tentang Kami"
        seoKeywords="JFE Engineering Indonesia, JFEEI, PT. JFEEI, Direktur JFEEI, Energi, Lingkungan, Konstruksi, Keberlanjutan, Pembangunan Berkelanjutan, SDG, Perusahaan EPC di Indonesia, Perusahaan EPC terbaik di Indonesia, Perusahaan EPC Jepang di Indonesia, Kualitas Jepang, Kebijakan Mutu JFEEI, Kebijakan Kesehatan JFEEI, Kebijakan Keselamatan JFEEI, Kebijakan Lingkungan JFEEI, Kebijakan JFE Engineering, Sertifikat JFEEI, Sertifikat JFE Engineering Indonesia, Sertifikat PT. JFE Engineering Indonesia"
      >
        <Navbar langKey={langKey} langs={langsMenu} url={url} />
        <PageHeader
          pageTitle="Tentang Kami"
          pageDescription={data.markdownRemark.frontmatter.header?.description}
        />
        <AboutUsContent />
        <Footer langKey={langKey} />
      </Layout>
    </IntlProvider>
  )
}

export default AboutUsId

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-id" } }) {
      frontmatter {
        header {
          description
        }
      }
    }
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
